import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title?: string
  description?: string
}

export const Seo: React.FC<Props> = ({ title, description }: Props) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
  </Helmet>
);

Seo.defaultProps = {
  title: 'Boston Heart Diagnostics',
  description: 'A website for BHD',
};
