import React from 'react';
// eslint-disable-next-line import/extensions, import/no-absolute-path
import Icon from '/sprites.svg';

interface Props {
  name: string;
  [key: string]: unknown;
}

export const Sprite: React.FC<Props> = ({ name, ...props }: Props) => (
  <svg {...props}>
    <use href={`${Icon}#${name}`} />
  </svg>
);
