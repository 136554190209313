import React from 'react';
import Spinner from 'react-activity/dist/Spinner';
import 'react-activity/dist/Spinner.css';

interface Props {
  children: React.ReactNode
  className?: string
  colorClassname?: string
  spacingClassName?: string
  type?: 'button' | 'submit' | 'reset'
  loading?: boolean
  spinnerSize?: number
  [key: string]: unknown
}

export const Button: React.FC<Props> = ({
  children, className, colorClassname, spacingClassName, type, loading, spinnerSize, ...props
}) => (
  // eslint-disable-next-line react/button-has-type
  <button type={type} disabled={loading} className={`${className} ${colorClassname} ${spacingClassName} text-lg rounded-lg ${loading && 'brightness-[85%]'} hover:brightness-[85%] transition-all duration-150`} {...props}>
    <div className="flex flex-row justify-center">
      {children}
      {' '}
      {loading && (
      <div className="pl-2 flex flex-col justify-center">
        <Spinner size={spinnerSize} />
      </div>
      )}
    </div>
  </button>
);

Button.defaultProps = {
  type: 'button',
  loading: false,
  spinnerSize: 12,
  className: '',
  colorClassname: 'bg-red text-white',
  spacingClassName: 'px-6 py-2',
};
