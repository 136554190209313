import React from 'react';

interface Props {
  src: string
}

export const Image: React.FC<Props> = ({ src }) => (
  <div
    className="w-full h-full bg-cover bg-center bg-no-repeat"
    style={{
      backgroundImage: `url('${src}')`,
    }}
  />
);
