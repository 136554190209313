import React, { useEffect, useMemo, useState } from 'react';
import validator from 'validator';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from './button';

interface Props {}

type Input = {
  code: string;
};

export const AccountDeletionForm: React.FC<Props> = ({}: Props) => {
  const { handleSubmit } = useForm<Input>();

  const navigate = useNavigate();
  const onSubmit = async (data: Input) => {
    if (typeof window != undefined) {
      window.location.href = '/product/standard/';
    } else {
      navigate('/product/standard/');
    }
  };

  const ulStyle = {
    listStyleType: 'd',
    margin: '20px',
    paddingLeft: '10px',
  };

  const liStyle = {
    marginBottom: '10px',
  };

  const aStyle = {
    color: 'blue',
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="font-inter text-gray-dark mt-6 leading-[21px]">
        To delete your user account, credentials and it's related records.
      </p>
      <ol style={ulStyle}>
        <li style={liStyle}>
          Please download our mobile app version: For{' '}
          <a
            style={aStyle}
            target="_blank"
            href="https://apps.apple.com/us/app/heartmap/id1663712923"
          >
            IOS
          </a>
          {' or '}
          <a
            style={aStyle}
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.bostonheartdiagnostics.heartmap"
          >
            Android
          </a>
        </li>
        <li style={liStyle}>Login using your user credentials.</li>
        <li style={liStyle}>
          Go to More &gt; My Account &gt; Delete Account (scroll to the bottom)
        </li>
        <li style={liStyle}>
          You'll be prompted to re-enter your email, enter your email.
        </li>
        <li style={liStyle}>Finally, press the 'Confirm Deletion' button.</li>
      </ol>
      <div className="border-t border-gray-medium w-full my-4" />
      <p className="font-inter text-gray-dark mt-6 leading-[21px]">
        Once accomplished, your account will be immediately unavailable.
      </p>
      <br />
      <fieldset className="flex flex-row mt-4">
        <Button className="flex-1" type="submit">
          Return
        </Button>
      </fieldset>
    </form>
  );
};

AccountDeletionForm.defaultProps = {};
