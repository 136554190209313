import { AccountDeletionForm } from '../components/confirm-account-deletion-form';
import { Seo } from '../components/seo';

function AccountDeletionPage() {
  return (
    <>
      <Seo title="Delete Account" />
      <div className="max-w-[311px]">
        <h2 className="font-inter font-bold text-[2rem] text-black">
          Delete Account
        </h2>
        <div className="mt-6">
          <AccountDeletionForm />
        </div>
      </div>
    </>
  );
}

export default AccountDeletionPage;
